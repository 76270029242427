import dynamic from 'next/dynamic'
import Intro from 'components/home/intro'
import SimpleSimulations from 'components/home/sample_simulations'
import Navigation from 'components/nav'
import Consent from 'components/consent'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import { useState, useEffect, useRef } from 'react'
const Why = dynamic(() => import('components/home/why'))
const Product = dynamic(() => import('components/home/product'))
const SimulationTypes = dynamic(() =>
  import('components/home/simulation_types')
)
const Testimonials = dynamic(() => import('components/home/testimonials'))
const Footer = dynamic(() => import('components/footer'))

export default function Index() {
  const [isSticky, setIsSticky] = useState(false)

  const whyRef = useRef(null)
  const [triggerElement, setTriggerElement] = useState(null)

  useEffect(() => {
    if (whyRef.current) {
      setTriggerElement(whyRef.current)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const targetElement = document.getElementById('why')
      const targetOffsetTop = targetElement?.offsetTop || 0

      if (window.scrollY >= targetOffsetTop) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  return (
    <>
      <div
        className={classNames('container-fluid px-0 h-100', {
          'sticky-top bg-primary': isSticky,
        })}
      >
        <div className="nav-header container-lg mx-auto">
          <header></header>
          <Navigation isCase={false} />
        </div>
      </div>
      <div className="home">
        <div className="container-fluid container-intro px-0">
          <Intro />
          <div className="scroll-msg-container d-none d-lg-block">
            <div className="scroll-msg-inner">
              <div className="scroll-msg-wheel" />
            </div>
          </div>
        </div>
        <div className="container-fluid px-0">
          <div ref={whyRef}>
            <Why />
          </div>
          <Product />
          {!isMobile && triggerElement && (
            <SimpleSimulations trigger={triggerElement} />
          )}
          <SimulationTypes />
          <Testimonials />
          <Footer showCredits={true} />
        </div>
      </div>
      <Consent />
    </>
  )
}
