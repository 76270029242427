import Image from 'next/image'
import Link from 'next/link'
import trustedBy from 'data/trusted_by'

export default function Marquee() {
  return (
    <div className="marquee marquee--reverse">
      <div className="marquee__group">
        {trustedBy.map((tb, i) => (
          <Link key={i} passHref href={tb.href} target="_blank">
            <Image
              src={tb.src}
              width={tb.width}
              height={tb.height}
              alt={tb.alt}
              priority={true}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Link>
        ))}
      </div>
      <div aria-hidden="true" className="marquee__group">
        {trustedBy.map((tb, i) => (
          <Link key={i} passHref href={tb.href} target="_blank">
            <Image
              src={tb.src}
              width={tb.width}
              height={tb.height}
              alt={tb.alt}
              priority={true}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Link>
        ))}
      </div>
    </div>
  )
}
