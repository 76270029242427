import { useState, useEffect } from 'react'

import dynamic from 'next/dynamic'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons/faXTwitter'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay'
import { isMobile } from 'react-device-detect'
import { faShapes } from '@fortawesome/pro-solid-svg-icons/faShapes'
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub'
import heroVideos from 'data/hero_videos'
import Marquee from 'components/marquee'

const ModalVideo = dynamic(() => import('react-modal-video'))
const Video = dynamic(() => import('./video'))
const FontAwesomeIcon = dynamic(() =>
  import('@fortawesome/react-fontawesome').then((fa) => fa.FontAwesomeIcon)
)

export default function Intro() {
  const [open, setOpen] = useState(false)

  const items = [
    '<strong>Pay per use</strong>: no hardware or software CAPEX',
    '<strong>Design faster</strong>: no CAD repair, manual meshing or post-processing',
    '<strong>Improve performance</strong>: more range, lower emissions, higher payload',
  ]
  const [currentItemIndex, setCurrentItemIndex] = useState(0)

  useEffect(() => {
    const fadeInterval = setInterval(() => {
      setCurrentItemIndex((prevIndex) => (prevIndex + 1) % items.length)
    }, 5000)

    return () => clearInterval(fadeInterval)
  }, [items.length])

  return (
    <div className="container-lg container-intro mx-auto pb-3 pb-md-0">
      <div className="intro">
        <h1 className="mb-5">
          Aerodynamics <br />
          <span>made easy</span>
        </h1>
        <div className="fade-container pb-5 text-sm-center text-lg-left">
          <ul className="fade-list pb-5">
            {items.map((item, index) => (
              <li
                dangerouslySetInnerHTML={{ __html: item }}
                key={index}
                className={`fade-item ${
                  index === currentItemIndex ? 'fade-in' : ''
                }`}
              ></li>
            ))}
          </ul>
        </div>
        <a
          href={
            isMobile
              ? 'https://app.airshaper.com/simulations/sports-car-design-advice-for-less-drag'
              : '#sample-simulations'
          }
          target={isMobile ? '_blank' : '_self'}
          rel="noopener noreferrer"
          className="btn btn-secondary fw-bold px-5 py-3 d-md-inline"
        >
          <FontAwesomeIcon icon={faShapes} /> Showcase
        </a>
        <a
          href="#"
          onClick={() => setOpen(!open)}
          className="btn btn-outline-light btn-how-it-works d-md-inline px-5 py-3 ms-md-2"
        >
          <FontAwesomeIcon icon={faPlay} /> How it Works
        </a>
        <ModalVideo
          isOpen={open}
          videoId="O_DNDxCtI_I"
          onClose={() => setOpen(!open)}
        />
        <div className="trusted-by mt-4 mt-sm-6 d-sm-block">
          <span>Trusted by</span>
          <Marquee></Marquee>
        </div>
        {/*<div className="trusted-by mt-4 d-none d-sm-block position-relative">
          <span>Social</span>
          <ul className="p-0 mt-2">
            <li>
              <a
                href="https://www.youtube.com/airshaper?sub_confirmation=1"
                style={{ color: '#fff' }}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon size="2x" icon={faYoutube} fixedWidth />
              </a>
            </li>
            <li className="ps-2">
              <a
                href="https://www.linkedin.com/company/airshaper/"
                style={{ color: '#fff' }}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon size="2x" icon={faLinkedin} fixedWidth />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/Air_Shaper"
                style={{ color: '#fff' }}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon size="2x" icon={faXTwitter} fixedWidth />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/AirShaper/"
                style={{ color: '#fff' }}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon size="2x" icon={faFacebook} fixedWidth />
              </a>
            </li>
            <li>
              <a
                href="https://github.com/airshaper/"
                style={{ color: '#fff' }}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon size="2x" icon={faGithub} fixedWidth />
              </a>
            </li>
          </ul>
        </div>*/}
      </div>

      {!isMobile && <Video videos={heroVideos} />}
    </div>
  )
}
