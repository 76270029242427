import dynamic from 'next/dynamic'
import { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

const LazyIframe = dynamic(() => import('../lazy_iframe'))

export default function SampleSimulations({ trigger = null }) {
  const [key, setKey] = useState('sports_car')

  return (
    <>
      <section className="sample-simulations pt-5" id="sample-simulations">
        <div className="container">
          <h2 className="title pb-lg-4">
            Analyze CFD Simulation Results
            <br /> Right in Your Browser
          </h2>
          <div className="col-lg-12">
            <Tabs
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="border-0 showcase justify-content-center"
            >
              <Tab eventKey="sports_car" title="Sports Car">
                {key === 'sports_car' && (
                  <LazyIframe
                    src="https://app.airshaper.com/simulations/sports-car-design-advice-for-less-drag/embed"
                    width="100%"
                    height="650"
                    title="Test"
                    trigger={trigger}
                  />
                )}
              </Tab>
              <Tab eventKey="drone" title="Drone">
                {key === 'drone' && (
                  <LazyIframe
                    src="https://app.airshaper.com/simulations/kolibri_simulation2/embed"
                    width="100%"
                    height="650"
                    title="Test"
                  />
                )}
              </Tab>
              <Tab eventKey="truck" title="Truck">
                {key === 'truck' && (
                  <LazyIframe
                    src="https://app.airshaper.com/simulations/vecto-base-design-advice-for-less-drag/embed"
                    width="100%"
                    height="650"
                    title="Test"
                  />
                )}
              </Tab>
              <Tab eventKey="pipe" title="Pipe">
                {key === 'pipe' && (
                  <LazyIframe
                    src="https://app.airshaper.com/simulations/pipe-0887e1/embed"
                    width="100%"
                    height="650"
                    title="Test"
                  />
                )}
              </Tab>
              <Tab eventKey="propeller" title="Propeller">
                {key === 'propeller' && (
                  <LazyIframe
                    src="https://app.airshaper.com/simulations/apc-prop-14x8/embed"
                    width="100%"
                    height="650"
                    title="Test"
                  />
                )}
              </Tab>
              <Tab eventKey="cooler" title="Cooler">
                {key === 'cooler' && (
                  <LazyIframe
                    src="https://app.airshaper.com/simulations/cooler-unit-with-2-fans-5-km-h-regular-simulation/embed"
                    width="100%"
                    height="650"
                    title="Test"
                  />
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>
    </>
  )
}
