import apteraLogo from '../public/assets/images/aptera_logo.svg'
import redbullLogo from '../public/assets/images/redbull_logo.svg'
import mvAgustaLogo from '../public/assets/images/mv-agusta.svg'
import nioLogo from '../public/assets/images/nio_logo.svg'
import bertoneLogo from '../public/assets/images/bertone.svg'
import wingtraLogo from '../public/assets/images/wingtra_white_logo.svg'
import geLogo from '../public/assets/images/general-electric.svg'
import morganLogo from '../public/assets/images/morgan-motors-logo.svg'
import palVLogo from '../public/assets/images/pal-v-logo.svg'
import demeLogo from '../public/assets/images/deme_white.svg'
import agEagleLogo from '../public/assets/images/ag_eagle_logo.svg'

const trustedBy = [
  {
    href: 'https://www.morgan-motor.com/',
    src: morganLogo,
    width: 150,
    height: 53,
    alt: 'Morgan Motor Company',
  },
  {
    href: 'https://ageagle.com/',
    src: agEagleLogo,
    width: 164,
    height: 54,
    alt: 'Ag Eagle - Drones, sensors and software for automated aerial intelligence',
  },
  {
    href: 'https://pal-v.com/',
    src: palVLogo,
    width: 123,
    height: 30,
    alt: 'Pal-V - World’s First Flying Car',
  },
  {
    href: 'https://www.ge.com/renewableenergy/home',
    src: geLogo,
    width: 50,
    height: 50,
    alt: 'General Electric Renewable Energy',
  },
  {
    href: 'https://www.redbull.com/',
    src: redbullLogo,
    width: 150,
    height: 30,
    alt: 'Redbull',
  },
  {
    href: 'https://www.aptera.us/',
    src: apteraLogo,
    width: 100,
    height: 23,
    alt: 'Aptera - Most Efficient EV',
  },
  {
    href: 'https://www.nio.com/',
    src: nioLogo,
    width: 150,
    height: 50,
    alt: 'Nio',
  },
  {
    href: 'https://www.mvagusta.com/',
    src: mvAgustaLogo,
    width: 80,
    height: 80,
    alt: 'MV Agusta',
  },
  {
    href: 'https://bertone.it/',
    src: bertoneLogo,
    width: 136,
    height: 36,
    alt: 'Bertone',
  },
  {
    href: 'https://wingtra.com',
    src: wingtraLogo,
    width: 177,
    height: 41,
    alt: 'Wingtra - Fast and accurate survey data every time',
  },
  {
    href: 'https://www.deme-group.com/',
    src: demeLogo,
    width: 80,
    height: 22,
    alt: 'DEME - a world leader in the specialised domains of dredging',
  },
]

export default trustedBy
